import React, { Component } from "react";

import './InfoPanel.css';

class InfoPanel extends Component {
	
	render() {
	    return(
	    	<div className="App-info-panel">
	          <h2>Our Mission</h2>
	          <p>
	            Here at Advantage we take pride in our ability to help others. We strive for simple, 
	            painless interactions, intended on improving our clients experiance's with their computers.
	          </p>
	        </div>
    	);
	}
}

export default InfoPanel;