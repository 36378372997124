import React, { Component } from "react";

import './PDFPanel.css';

class PDFPanel extends Component {
	
	render() {
	    return(
	    	<div className="App-pdf-panel">
	          <h2>Help Guide's</h2>
	          <ul className="pdf-link-list">
	            <li><a className="pdf-link" href="https://downloads.advcustomerservice.com/pdf/001">First Time Emsisoft Installation</a></li>
	            <li><a className="pdf-link" href="https://downloads.advcustomerservice.com/pdf/002">Renewing an Emsisoft Licsense</a></li>
	            <li><a className="pdf-link" href="https://downloads.advcustomerservice.com/pdf/003">Installing Office Starter 2010</a></li>
	            <li><a className="pdf-link" href="https://downloads.advcustomerservice.com/pdf/004">Adding Ad-Block to Google Chrome</a></li>
	            <li><a className="pdf-link" href="https://downloads.advcustomerservice.com/pdf/005">Configuring Open DNS</a></li>
	          </ul>
	        </div>
    	);
	}
}

export default PDFPanel;