import React, { Component } from "react";

import './LocationPanel.css';

class LocationPanel extends Component {
	
	render() {
	    return(
	    	<div className="App-contact-panel">
	          <div className="contact-location">
	            <h2>Hours</h2>
	            <div className="location-text">
	              <p className="location-text-hours">Monday - Friday&nbsp;:&nbsp; <b>9</b>&nbsp;am&nbsp;-&nbsp;<b>4&nbsp;</b>pm</p>
	              <p className="location-text-hours">Saturday and Sunday&nbsp;:&nbsp;<b>Closed</b></p>
	            </div>
	            
	            <h2>Location</h2>
	            <div className="location-text">
	              <p className="location-text-address">150 S Camino Seco, Suite 118</p>
	              <p className="location-text-address">Tucson, AZ 85710</p>
	            </div>
	            
	            <div className="location-map">
	              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2838.5065603034122!2d-110.8064471985359!3d32.215756332530475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86d66927db70a1f9%3A0x26bcadcfa6413357!2sAdvantage%20Micro%20Corporation!5e0!3m2!1sen!2sus!4v1644435057773!5m2!1sen!2sus" width="100%" height="200px" title="AdvantageMicro" allowFullScreen="" loading="lazy"></iframe>
	            </div>
	          </div>
	        </div>
    	);
	}
}

export default LocationPanel;