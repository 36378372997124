import React, { Component } from "react";

import "./Footer.css"

class Footer extends Component {
  
	render() {
    	return(
			<footer className="App-footer">
				<div className="Footer-phone"><a href="tel:520-290-0595">(520)-290-0595</a></div>
				<div className="Footer-contact">
					<p className="Contact-email">jr@advantage77.com</p>
					<a className="Contact-google-review" href="https://search.google.com/local/writereview?placeid=ChIJ-aFw2ydp1oYRVzNBps-tvCY">Leave us a review!</a>
				</div>
			</footer>
		)
	}
}

export default Footer;