import React, { Component } from "react";

import "./Header.css"

class Header extends Component {
  
	render() {
    	return(
    		<header className="App-header">
	        	<h1>Advantage Micro</h1>
	        	<h2>Customer Service</h2>
	      	</header>
		)
	}
}

export default Header;