import React, { Component } from "react";

import InfoPanel from './components/InfoPanel';
import LocationPanel from './components/LocationPanel';
import PDFPanel from './components/PDFPanel';

import './Body.css';

class Body extends Component {
  
  render() {
    return(
      <section className="App-content">

        <InfoPanel />
        
        <PDFPanel />

        <LocationPanel />

      {/*Spacer added to resolve conflict with map collision on footer*/}
        <div className="App-panel-spacer"></div>

      </section>
    )
  }
}

export default Body;